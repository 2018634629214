<template>
	<v-sheet class="customer leads-container" style="height: calc(100vh - 88px)">
		<v-layout>
			<v-flex class="py-2">
				<p
					class="my-auto text-uppercase custom-title font-level-3-bold fw-600"
					style="margin-top: -4px !important; font-size: 20px !important"
				>
					My Calendar
				</p>
			</v-flex>
		</v-layout>
	</v-sheet>
</template>
<script>
export default {
	name: "task-listing",
	data() {
		return {};
	},
};
</script>
